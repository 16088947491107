import React from "react"
import { Link } from "gatsby"

const LinkLabel = ({ children, to }) => {
  return (
    <Link
      style={{
        padding: 5,
        background: "black",
        color: "white",
        borderRadius: 5,
      }}
      to={to}
    >
      {children}
    </Link>
  )
}

export default LinkLabel
